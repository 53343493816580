import { differenceInMilliseconds, differenceInSeconds } from "date-fns";
import createDebug from "debug";
import { useCallback, useEffect, useMemo, useState } from "react";
import { A, O } from "ts-toolbelt";
import useFetch, { CachePolicies, UseFetch } from "use-http";

import { useRequiredAuthContext } from "~/components/auth/common";
import { AreaChartModel } from "~/components/core/charts/AreaChart/AreaChart";
import { useShowSnack } from "~/components/layouts/common/SnackLayout";
import { API_URL } from "~/constants/api";
import { ChatMessage, Lesson } from "~/declarations/models/Lesson";
import { Operator } from "~/declarations/models/Operator";
import { PowerUsers } from "~/declarations/models/PowerUser";
import { IQuickFacts } from "~/declarations/models/QuickFacts";
import { IQuote, RatingsData } from "~/declarations/models/Rating";
import { Student } from "~/declarations/models/Student";
import { ISubjectStatData, SubjectGroup } from "~/declarations/models/Subject";
import { User } from "~/declarations/models/User";
import { WritingLab } from "~/declarations/models/WritingLab";
import { TableData } from "~/declarations/table-data";

const debug = createDebug("dashboard:http");

export type DashboardApiQuery = {
  lessons: "all" | "moderated";
  umbrellaAccountId: string;
  fromDt: string;
  tillDt: string;
  scale: "hourly" | "daily" | "weekly" | "monthly";
  tz: string;
  subjects?: string;
  isStudentRatings?: boolean;
};

type DashboardChartEndpoint = {
  query: DashboardApiQuery;
  // eslint-disable-next-line @typescript-eslint/ban-types
  body: {};
  response: AreaChartModel[];
};

type DashboardGeneralStatsEndpoint<T extends string> = {
  query: { umbrellaAccountId: string };
  // eslint-disable-next-line @typescript-eslint/ban-types
  body: {};
  response: {
    [key in T]: number;
  };
};

type CombinedReportsStatsEndpoint<T extends string> = {
  query: A.Omit<DashboardApiQuery, "subjects" | "tz" | "scale">;
  // eslint-disable-next-line @typescript-eslint/ban-types
  body: {};
  response: {
    [key in T]: number;
  };
};

type LiveReportsStatsEndpoint<T extends string> = {
  query: A.Omit<DashboardApiQuery, "tz" | "scale">;
  // eslint-disable-next-line @typescript-eslint/ban-types
  body: {};
  response: {
    [key in T]: number;
  };
};

type WLReportsStatsEndpoint<T extends string> = {
  query: A.Omit<DashboardApiQuery, "subjects" | "tz" | "scale">;
  // eslint-disable-next-line @typescript-eslint/ban-types
  body: {};
  response: {
    [key in T]: number;
  };
};

export type ApiTypes = {
  "/api/v1/auth/pear/redirect/": {
    // eslint-disable-next-line @typescript-eslint/ban-types
    query: {};
    body: {
      access: string;
      refresh: string;
    };
    response: {
      access: string;
      refresh: string;
    };
  };
  "/api/v1/auth/jwt/create_google/": {
    // eslint-disable-next-line @typescript-eslint/ban-types
    query: {};
    body: {
      access_token?: string | undefined;
    };
    response: {
      access: string;
      refresh: string;
    };
  };
  "/api/v1/auth/jwt/create/": {
    // eslint-disable-next-line @typescript-eslint/ban-types
    query: {};
    body: {
      email: string;
      password: string;
    };
    response: {
      access: string;
      refresh: string;
    };
  };
  "/api/v1/auth/jwt/refresh/": {
    // eslint-disable-next-line @typescript-eslint/ban-types
    query: {};
    body: {
      refresh: string;
    };
    response: {
      access: string;
      refresh: string;
    };
  };
  "/api/v1/account/passwd_reset/": {
    // eslint-disable-next-line @typescript-eslint/ban-types
    query: {};
    body: {
      userId: number;
      code: string;
      password: string;
      passwordConfirmation: string;
    };
    response: undefined;
  };
  "/api/v1/account/me/": {
    // eslint-disable-next-line @typescript-eslint/ban-types
    query: {};
    body: User;
    response: User;
  };
  "/api/v1/account/send_password_reset/": {
    // eslint-disable-next-line @typescript-eslint/ban-types
    query: {};
    body: {
      email: string;
    };
    response: undefined;
  };
  "/api/v1/account/add_operator/": {
    // eslint-disable-next-line @typescript-eslint/ban-types
    query: {};
    body: {
      email: string;
      firstName: string;
      lastName: string;
      umbrellaAccountId: string;
    };
    response: undefined;
  };
  "/api/v1/account/delete_operator/": {
    // eslint-disable-next-line @typescript-eslint/ban-types
    query: {};
    body: {
      userId: number;
    };
    response: undefined;
  };
  "/api/v1/account/update_operator_permissions/": {
    // eslint-disable-next-line @typescript-eslint/ban-types
    query: {};
    body: {
      userId: number;
      manageUsers: boolean;
    };
    response: undefined;
  };
  "/api/v1/account/signup/": {
    // eslint-disable-next-line @typescript-eslint/ban-types
    query: {};
    body: {
      firstName: string;
      lastName: string;
      password: string;
      passwordConfirmation: string;
      code: string;
    };
    response: User & {
      access: string;
      refresh: string;
    };
  };
  "/api/v1/stats/students/": {
    query: {
      searchPhrase: string | null;
      minutesLeft: number | null;
      cleverFacet?: string;
      limit: number;
      offset: number;
      umbrellaAccountId: string;
      ordering: string | null;
    };
    // eslint-disable-next-line @typescript-eslint/ban-types
    body: {};
    response: TableData<Student>;
  };
  "/api/v1/account/my_operators/": {
    query: {
      umbrellaAccountId: string;
    };
    // eslint-disable-next-line @typescript-eslint/ban-types
    body: {};
    response: Operator[];
  };
  "/api/v1/subjects/": {
    // eslint-disable-next-line @typescript-eslint/ban-types
    query: {};
    // eslint-disable-next-line @typescript-eslint/ban-types
    body: {};
    response: SubjectGroup[];
  };

  "/api/v1/charts/combined_hours/": DashboardChartEndpoint;
  "/api/v1/charts/combined_sessions/": DashboardChartEndpoint;
  "/api/v1/charts/live_hours/": DashboardChartEndpoint;
  "/api/v1/charts/live_sessions/": DashboardChartEndpoint;
  "/api/v1/charts/wl_hours/": DashboardChartEndpoint;
  "/api/v1/charts/wl_sessions/": DashboardChartEndpoint;
  "/api/v1/charts/comparison_sessions/": DashboardChartEndpoint;
  "/api/v1/charts/active_students/": DashboardChartEndpoint;

  "/api/v1/new_stats/students_enrolled/": DashboardGeneralStatsEndpoint<
    "studentsEnrolled"
  >;
  "/api/v1/new_stats/hours_used/": DashboardGeneralStatsEndpoint<"hoursUsed">;
  "/api/v1/new_stats/total_sessions_completed/": DashboardGeneralStatsEndpoint<
    "totalSessionsCompleted"
  >;

  "/api/v1/new_stats/combined/active_students/": CombinedReportsStatsEndpoint<
    "activeStudents"
  >;

  "/api/v1/new_stats/combined/hours_used/": CombinedReportsStatsEndpoint<
    "hoursUsed"
  >;

  "/api/v1/new_stats/combined/average_rating/": CombinedReportsStatsEndpoint<
    "averageRating"
  >;

  "/api/v1/new_stats/live/average_lesson_length/": LiveReportsStatsEndpoint<
    "averageLessonLength"
  >;

  "/api/v1/new_stats/live/average_wait_time/": LiveReportsStatsEndpoint<
    "averageWaitTime"
  >;

  "/api/v1/new_stats/live/average_rating/": LiveReportsStatsEndpoint<
    "averageRating"
  >;

  "/api/v1/new_stats/wl/average_paper_length/": WLReportsStatsEndpoint<
    "averagePaperLength"
  >;

  "/api/v1/new_stats/wl/papers_reviewed/": WLReportsStatsEndpoint<
    "papersReviewed"
  >;

  "/api/v1/new_stats/wl/average_rating/": WLReportsStatsEndpoint<
    "averageRating"
  >;

  "/api/v1/new_stats/wl/average_turnaround_time/": WLReportsStatsEndpoint<
    "averageTurnaroundTime"
  >;

  "/api/v1/new_stats/session_ratings/": {
    query: O.Merge<
      A.Omit<DashboardApiQuery, "scale">,
      { isStudentRatings: boolean }
    >;
    // eslint-disable-next-line @typescript-eslint/ban-types
    body: {};
    response: RatingsData;
  };

  "/api/v1/new_stats/writing_lab_ratings/": {
    query: A.Omit<DashboardApiQuery, "scale">;
    // eslint-disable-next-line @typescript-eslint/ban-types
    body: {};
    response: RatingsData;
  };

  "/api/v1/new_stats/session_quotes/": {
    query: A.Omit<DashboardApiQuery, "scale">;
    // eslint-disable-next-line @typescript-eslint/ban-types
    body: {};
    response: IQuote[];
  };
  "/api/v1/new_stats/wl_quotes/": {
    query: A.Omit<DashboardApiQuery, "scale">;
    // eslint-disable-next-line @typescript-eslint/ban-types
    body: {};
    response: IQuote[];
  };

  "/api/v1/new_stats/combined/power_sessions/": {
    query: A.Omit<DashboardApiQuery, "scale">;
    // eslint-disable-next-line @typescript-eslint/ban-types
    body: {};
    response: PowerUsers[];
  };

  "/api/v1/new_lessons/": {
    query: O.Merge<
      A.Omit<DashboardApiQuery, "scale" | "tz">,
      {
        searchPhrase?: string;
        studentId?: number;
        ordering?: string;
        cleverFacet?: string;
        limit: number;
        offset: number;
        studentRating?: string;
        tutorRating?: string;
      }
    >;
    // eslint-disable-next-line @typescript-eslint/ban-types
    body: {};
    response: TableData<Lesson>;
  };
  "/api/v1/new_lessons/transcript/": {
    query: {
      umbrellaAccountId: string;
      lessonId: number;
    };
    // eslint-disable-next-line @typescript-eslint/ban-types
    body: {};
    response: {
      id: number;
      createdAt: string;
      studentDisplayName: string;
      studentEmail: string;
      studentId: number;
      tutorDisplayName: string;
      duration: number;
      screenshot: string | null;
      subjectName: string;
      messages: ChatMessage[] | null;
      videoUrl: string | null;
    };
  };

  "/api/v1/new_wls/": {
    query: O.Merge<
      A.Omit<DashboardApiQuery, "scale" | "tz">,
      {
        searchPhrase?: string;
        cleverFacet?: string;
        studentId?: number;
        ordering?: string;
        limit: number;
        offset: number;
        studentRating?: string;
      }
    >;
    // eslint-disable-next-line @typescript-eslint/ban-types
    body: {};
    response: TableData<WritingLab>;
  };

  "/api/v1/new_stats/clever_facets/": {
    query: {
      umbrellaAccountId: string;
      source: "students" | "lessons" | "wls";
    };
    // eslint-disable-next-line @typescript-eslint/ban-types
    body: {};
    response: Array<{ type: string; value: string }>;
  };

  "/api/v1/new_stats/quick_facts/": {
    query: {
      umbrellaAccountId: string;
      fromDt: string;
    };
    // eslint-disable-next-line @typescript-eslint/ban-types
    body: {};
    response: IQuickFacts;
  };

  "/api/v1/new_stats/subject_stats/": {
    query: {
      umbrellaAccountId: string;
      grades?: number;
      fromDt: string;
      tillDt: string;
    };
    // eslint-disable-next-line @typescript-eslint/ban-types
    body: {};
    response: ISubjectStatData[];
  };
};

export function flattenDrfErrors<T extends { [K in keyof T]?: [string] }>(
  errors: T
) {
  const res: { [K in keyof T]?: string } = {};
  for (const key of Object.keys(errors)) {
    const value = errors[key as keyof T];
    if (Array.isArray(value) && typeof value[0] === "string") {
      res[key as keyof T] = value[0];
    }
  }
  return res;
}

export function convertDrfErrors<T extends { [K in keyof T]?: [string] }>(
  errors: T
) {
  const res: Array<{
    name: keyof T;
    type: string;
    message: string;
  }> = [];
  for (const key of Object.keys(errors)) {
    const value = errors[key as keyof T];
    if (Array.isArray(value) && typeof value[0] === "string") {
      res.push({
        name: key as keyof T,
        type: "drf",
        message: value[0]
      });
    }
  }
  return res;
}

type ResponseType<K extends keyof ApiTypes> =
  | {
      status: 200;
      data: ApiTypes[K]["response"];
    }
  | {
      status: 400;
      data: {
        [J in keyof O.Merge<ApiTypes[K]["body"], ApiTypes[K]["query"]>]?: [
          string
        ];
      };
    }
  | {
      status: 202 | 201 | 401 | 403 | 404 | 500;
    };

function responseJsonWithStatus<K extends keyof ApiTypes>(
  response: Response
): Promise<A.Compute<ResponseType<K>>> {
  const status = response.status;
  if (status === 200 || status === 400) {
    if (response.headers.get("Content-Type")?.includes("/json")) {
      return response.json().then(data => ({
        status,
        data
      }));
    }
    throw new Error("missing response body");
  } else if (
    status === 201 ||
    status === 202 ||
    status === 401 ||
    status === 403 ||
    status === 404 ||
    status === 500
  ) {
    return Promise.resolve({
      status
    });
  }
  throw new Error(`unsupported http status ${status}`);
}

type Query = {
  [key: string]: string | number | boolean | null | undefined;
};

function debugQuery(query: {
  [key: string]: string | number | boolean | null | undefined;
}): string {
  const queryStringComponents = Object.entries(query).flatMap(
    ([key, value]) => {
      const name = encodeURIComponent(key);
      if (typeof value === "number") {
        return [`${name}=${value.toFixed(0)}`];
      }
      if (typeof value === "string" && value.length > 0) {
        return [`${name}=${value}`];
      }
      return [];
    }
  );
  queryStringComponents.sort();
  return queryStringComponents.length ? queryStringComponents.join(" ") : "";
}

function stringifyQuery(query: {
  [key: string]: string | number | boolean | null | undefined;
}): string {
  const queryStringComponents = Object.entries(query).flatMap(
    ([key, value]) => {
      const name = encodeURIComponent(key);
      if (typeof value === "number") {
        return [`${name}=${value.toFixed(0)}`];
      }
      if (typeof value === "string" && value.length > 0) {
        return [`${name}=${encodeURIComponent(value)}`];
      }
      if (typeof value === "boolean") {
        return [`${name}=${value ? "true" : "false"}`];
      }
      return [];
    }
  );
  queryStringComponents.sort();
  return queryStringComponents.length
    ? `?${queryStringComponents.join("&")}`
    : "";
}

type ResponseTypeWithUndefinedStatus<K extends keyof ApiTypes> = O.Optional<
  UseFetch<ApiTypes[K]["response"]>["response"],
  "status"
>;

function buildUrl<K extends keyof ApiTypes>(
  pathname: K,
  query: Query = {}
): string {
  const cleanPathname = pathname.startsWith("/") ? pathname.slice(1) : pathname;
  return `${API_URL}${cleanPathname}${stringifyQuery(query)}`;
}

function formatRequestDuration(start: Date) {
  const now = new Date();
  const diffMs = differenceInMilliseconds(now, start);
  if (diffMs > 1000) {
    return `${differenceInSeconds(now, start)} seconds`;
  }
  return `${diffMs} milliseconds`;
}

type ErrorResponse = {
  message: string;
};

class ApiError extends Error {
  constructor(message: string) {
    super(`api: ${message}`);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isErrorResponse(data: any): data is ErrorResponse {
  return (
    typeof data === "object" &&
    data !== null &&
    data.hasOwnProperty("message") &&
    typeof data.message === "string"
  );
}

export function useAuthenticatedFetch<K extends keyof ApiTypes>(
  pathname: K,
  query: ApiTypes[K]["query"],
  useCache = false
) {
  const url = buildUrl(pathname, query);
  const { readRequestHeaders } = useRequiredAuthContext();
  const [manualExec, setManualExec] = useState(0);
  const refetch = useCallback(() => {
    setManualExec(x => x + 1);
  }, []);
  const showSnack = useShowSnack();
  const fetchApi = useFetch<ApiTypes[K]["response"]>(
    url,
    {
      method: "GET",
      headers: readRequestHeaders,
      cachePolicy: useCache
        ? CachePolicies.CACHE_FIRST
        : CachePolicies.NETWORK_ONLY,
      mode: "cors",
      credentials: "omit",
      timeout: 60_000,
      responseType: "json",
      interceptors: {
        request: async ({ options }) => {
          debug(
            "useFetch %c%s %s",
            "font-weight: bold",
            pathname,
            debugQuery(query)
          );
          return options;
        },
        response: async ({ response }) => {
          if (response.status !== 200) {
            if (response.headers.get("content-type") === "application/json") {
              const data = await response.json();
              if (isErrorResponse(data)) {
                throw new ApiError(data.message);
              }
            }
            throw new Error(`http: ${response.statusText ?? response.status}`);
          }
          return response;
        }
      },
      suspense: false
    },
    [url, manualExec]
  );
  const result = useMemo(
    () => ({
      data: fetchApi.data,
      loading: fetchApi.loading,
      error: fetchApi.error,
      response: fetchApi.response as ResponseTypeWithUndefinedStatus<K>,
      refetch
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      fetchApi.data,
      fetchApi.error,
      fetchApi.loading,
      fetchApi.response,
      refetch
    ]
  );
  useEffect(() => {
    if (
      process.env.NODE_ENV === "development" &&
      result.error &&
      !(result.error instanceof ApiError) &&
      !result.error.message.startsWith("api: ")
    ) {
      console.warn(result.error);
      showSnack.error(url);
    }
  }, [result.error, showSnack, url]);
  return result;
}

export function authenticatedGet<K extends keyof ApiTypes>(
  pathname: K,
  accessToken: string,
  query: ApiTypes[K]["query"]
) {
  debug("GET %c%s %s", "font-weight: bold", pathname, debugQuery(query));
  const url = buildUrl(pathname, query);
  const start = new Date();
  return fetch(url, {
    method: "GET",
    mode: "cors",
    credentials: "omit",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`
    }
  })
    .then(response => responseJsonWithStatus<K>(response))
    .then(response => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error(`http status ${response.status}`);
    })
    .finally(() => {
      debug(
        "GET %c%s%c took %s",
        "font-weight: bold",
        pathname,
        "font-weight: normal",
        formatRequestDuration(start)
      );
    });
}

export function authenticatedPatch<K extends keyof ApiTypes>(
  pathname: K,
  data: Partial<ApiTypes[K]["body"]>,
  accessToken: string
): Promise<ResponseType<K>> {
  const start = new Date();
  return fetch(buildUrl(pathname), {
    method: "PATCH",
    mode: "cors",
    credentials: "omit",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })
    .then(response => responseJsonWithStatus<K>(response))
    .finally(() => {
      debug(
        "PATCH %c%s%c took %s",
        "font-weight: bold",
        pathname,
        "font-weight: normal",
        formatRequestDuration(start)
      );
    });
}

export function authenticatedPost<K extends keyof ApiTypes>(
  pathname: K,
  data: ApiTypes[K]["body"],
  accessToken: string
): Promise<ResponseType<K>> {
  const start = new Date();
  return fetch(buildUrl(pathname), {
    method: "POST",
    mode: "cors",
    credentials: "omit",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })
    .then(response => responseJsonWithStatus<K>(response))
    .finally(() => {
      debug(
        "POST %c%s%c took %s",
        "font-weight: bold",
        pathname,
        "font-weight: normal",
        formatRequestDuration(start)
      );
    });
}

export function anonymousPost<K extends keyof ApiTypes>(
  pathname: K,
  data: ApiTypes[K]["body"]
) {
  const start = new Date();
  return fetch(buildUrl(pathname), {
    method: "POST",
    mode: "cors",
    credentials: "omit",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })
    .then(response => responseJsonWithStatus<K>(response))
    .finally(() => {
      debug(
        "POST %c%s%c took %s",
        "font-weight: bold",
        pathname,
        "font-weight: normal",
        formatRequestDuration(start)
      );
    });
}
