import React from "react";

import { UmbrellaAccount } from "~/declarations/models/UmbrellaAccount";
import { User } from "~/declarations/models/User";

export const AuthContext = React.createContext<{
  user: User;
  setUser: (user: User) => void;
  selectedUmbrella: UmbrellaAccount;
  onSelectUmbrella: (value: UmbrellaAccount) => void;
  accessToken: string;
  signOut: () => void;
  readRequestHeaders: HeadersInit;
  writeRequestHeaders: HeadersInit;
  schoolYearStartDate: string;
  setSchoolYearStartDate: (date: string) => void;
}>(undefined as never);

export function useRequiredAuthContext() {
  const context = React.useContext(AuthContext);
  if (typeof context === "undefined") {
    throw new Error("AuthContext isnt available");
  }
  return context;
}

export function useOptionalAuthContext() {
  const context = React.useContext(AuthContext);
  if (typeof context === "undefined") {
    return undefined;
  }
  return context;
}
